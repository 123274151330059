<template>
  <section id="nf-flow-table">
    <b-table-simple id="nf-table" responsive borderless fixed class="table">
      <thead>
        <th>Data de Emissão</th>
        <th>Situação</th>
        <th>Paciente/CPF</th>
        <th>N° da Nota Fiscal</th>
        <th>Prestador</th>
        <th>Valor total</th>
        <th>Download</th>
        <th class="text-center" @click="canRetryControlIncrement">Ações</th>
      </thead>

      <tbody v-if="nfTableData.length">
        <tr v-for="(nf, index) in nfTableData" :key="index" class="nf-row">
          <td class="text-align-center">
            {{ parseDate(nf.data_emissao) }}
          </td>
          <td class="text-align-center nf-status__column">
            <span class="nf-status autorized" v-if="nf.status === 'autorizado'">
              {{ nfStatusList[nf.status] }}
            </span>
            <span
              class="nf-status scheduled"
              v-if="nf.status === 'processando_autorizacao'"
            >
              {{ nfStatusList[nf.status] }}
            </span>
            <span
              class="nf-status error"
              v-if="nf.status === 'erro_autorizacao'"
            >
              {{ nfStatusList[nf.status] }}
            </span>
            <span class="nf-status canceled" v-if="nf.status === 'cancelado'">
              {{ nfStatusList[nf.status] }}
            </span>
            <span class="nf-status error" v-if="nf.status === 'erro_cancelamento'">
              {{ nfStatusList[nf.status] }}
            </span>

          </td>
          <td class="text-align-center">
            {{ nf.nome_tomador }}
            <br />
            {{ cpfFormated(nf.cpf_cnpj_tomador) }}
          </td>
          <td class="text-align-center">
            {{ nf.numero }}
          </td>
          <td class="text-align-center">
            <p v-if="nf.nome_prestador">
              {{ nf.nome_prestador }}
            </p>
            <p v-else>...</p>
          </td>
          <td class="text-align-center">
            {{ parseNumberToMoney(nf.value) }}
          </td>
          <td class="text-align-center download">
            <span
              v-if="nf.caminho_pdf_nota_fiscal"
              class="download__item pdf"
              @click="downloadPDF(nf)"
            >
              <PdfIcon /> PDF
            </span>
            <span v-else class="download__item pdf download__item--disabled">
              <PdfIcon /> PDF
            </span>
          </td>
          <td class="text-align-center actions__column">
            <div>
              <Icon tooltip="Nova NF modelo" class="icon-box">
                <DuplicateNF class="action__item" @click="duplicateNF(nf)" />
              </Icon>
            </div>

            <div>
              <Icon tooltip="Reenviar por email" class="icon-box">
                <SendNF class="action__item" @click="resendEmail(nf)" />
              </Icon>
            </div>

            <div v-if="canRetry">
              <Icon tooltip="Reenviar nota" class="icon-box">
                <SendNF class="action__item" @click="!loading && retry(nf)" />
              </Icon>
            </div>

            <div>
              <Icon tooltip="Cancelar nota" class="icon-box">
                <Trash
                  class="action__item"
                  :class="{ disabled: nf.status === 'cancelado' }"
                  :disabled="nf.status === 'cancelado'"
                  @click="deleteNF(nf)"
                />
              </Icon>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr class="empty-state">
          Não há notas emitidas disponíveis ou nenhum resultado foi encontrado
          com base no filtro aplicado
        </tr>
      </tbody>
    </b-table-simple>

    <ResendNfModal :nfData="nfData" />
    <CancelIssueNfModal :nfData="nfData" />
  </section>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/modules/nfe/api'
import { parseDate } from '@/utils/dateHelper'
import { cpfFormated } from '@/utils/dataHelper.js'
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  components: {
    CancelIssueNfModal: () => import('@/modules/nfe/modals/CancelIssueNfModal'),
    ResendNfModal: () => import('@/modules/nfe/modals/ResendNfModal'),
    DuplicateNF: () => import('@/assets/icons/duplicate-nf.svg'),
    PdfIcon: () => import('@/assets/icons/pdf-icon.svg'),
    Trash: () => import('@/assets/icons/delete-nf.svg'),
    SendNF: () => import('@/assets/icons/send-nf.svg'),
    Icon: () => import('@/components/General/Icon'),
  },
  computed: {
    ...mapState('nfe', ['nfModalData', 'nfTableData'])
  },
  data() {
    return {
      loading: false,
      canRetryControl: 0,
      canRetry: false,
      nfData: {},
      nfStatusList: {
        processando_autorizacao: 'Processando',
        autorizado: 'Autorizado',
        erro_autorizacao: 'Erro de Aut.',
        cancelado: 'Cancelado',
        erro_cancelamento: 'Erro Cancelamento',
      }
    }
  },
  methods: {
    parseDate,
    cpfFormated,
    parseNumberToMoney,
    downloadPDF(nf) {
      window.open(nf.caminho_pdf_nota_fiscal, '_blank')
    },
    async downloadXML(nf) {
      const xmlData = nf.caminho_pdf_nota_fiscal
      const blob = new Blob([xmlData], { type: 'application/xml' })
      const link = document.createElement('a')

      link.href = window.URL.createObjectURL(blob)
      link.download = `NF-${nf.nome_prestador}-${parseDate(new Date())}.xml`

      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
    },
    async duplicateNF(nf) {
      const isLoading = this.$loading.show()

      try {
        const { data } = await api.getNFbyId(nf.id)
        const providerSelected = {
          id: data.prestador_id,
          clinic_id: data.clinic_id,
          cnpj: data.cnpj_prestador,
          razao_social: data.razao_social_prestador,
          config: data.prestador.config
        }

        this.$store.dispatch(
          'nfe/updateProviderModalSelected',
          providerSelected
        )
        const modalData = {
          data_emissao: new Date(data.data_emissao),
          prestador_cnpj: data.cnpj_prestador,
          prestador_id: data.prestador_id,
          prestador_razao_social: data.razao_social_prestador,
          servico: {
            aliquota: data.aliquota,
            discriminacao: data.discriminacao,
            valor_servicos: data.value,
            percentage_tax_burden: data.percentage_tax_burden,
            source_tax_burden: data.source_tax_burden,
            withhold_taxes: data.withhold_taxes,
            approximate_value_tax_burden: data.approximate_value_tax_burden,
            net_value: 0,
            value_inss: data.value_inss,
            value_irrf: data.value_irrf,
            value_csll: data.value_csll,
            value_cofins: data.value_cofins,
            value_pis_pasep: data.value_pis_pasep,
            percentage_inss: String(data.percentage_inss).replace('.', ','),
            percentage_irrf: String(data.percentage_irrf).replace('.', ','),
            percentage_csll: String(data.percentage_csll).replace('.', ','),
            percentage_cofins: String(data.percentage_cofins).replace('.', ','),
            percentage_pis_pasep: String(data.percentage_pis_pasep).replace('.', ',')
          },
          tomador: {
            cnpj_cpf: data.cpf_cnpj_tomador,
            email: data.email_tomador,
            nome: data.nome_tomador,
            endereco: {
              logradouro: null,
              numero: null,
              complemento: null,
              bairro: null,
              codigo_municipio: null,
              uf: null,
              cep: null
            }
          }
        }

        this.$store.dispatch('nfe/updateNfModalData', modalData)
      } catch (err) {
        this.$toast.error(err.message)
      } finally {
        isLoading.hide()
      }

      this.$bvModal.show('new-invoice-modal')
    },
    async resendEmail(nf) {
      this.nfData = nf
      this.$bvModal.show('resend-nf-modal')
    },
    retry(nf) {
      this.loading = true
      api.retry(nf.id)
      .then(() => this.$toast.success('Nota reenviada com sucesso!'))
      .catch(err => this.$toast.error(err.message))
      .finally(() => (this.loading = false));
    },
    async deleteNF(nf) {
      this.nfData = nf
      this.$bvModal.show('cancel-issue-nf-modal')
    },
    canRetryControlIncrement() {
      this.canRetryControl++
      this.canRetry = this.canRetryControl > 10
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/main';
@import '@/assets/scss/table.scss';

#nf-flow-table {
  .table {
    thead th {
      font-size: 14px !important;
      color: var(--dark-blue);
    }

    .nf-row {
      td {
        border: none !important;
        padding: 0.75rem !important;
        vertical-align: middle !important;
      }

      .nf-status__column {
        padding-left: 0 !important;
      }

      .nf-status {
        display: block;
        font-size: 12px;
        font-weight: 600;
        text-align: center;
        min-width: 100px;
        max-width: 120px;
        border-radius: 100px;
        font-family: 'Nunito Sans';
        padding: $padding-xs $padding-sm;
        &.autorized {
          color: var(--states-success-dark-green);
          background: var(--states-success-soft-green);
        }
        &.scheduled {
          background: var(--light-orange-200);
        }
        &.error {
          color: var(--states-error);
          border: 1px solid var(--states-error);
        }
        &.canceled {
          background: var(--states-red-soft);
        }
      }
    }
  }

  .empty-state {
    width: 300%;
    margin: 2rem;
    border: none;
    display: flex;
    font-size: 1rem;
    font-weight: 600;
    text-align: center;
    color: #525c7a;

    position: relative;
    left: 220%;
  }

  #nf-table .actions__column {
    display: flex;
    padding-top: 1.2rem !important;
    justify-content: center;

    .action__item {
      cursor: pointer;
      margin-right: $margin-xs;

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .download {
    .download__item {
      cursor: pointer;
      border-radius: 50px;
      color: var(--blue-500);
      margin-right: $margin-xxs;
      padding: $padding-xxs 10px;
      border: 1px solid var(--blue-500);
    }

    .download__item--disabled {
      cursor: not-allowed;
      color: var(--neutral-200);
      border-color: var(--neutral-200);
    }
  }
}
</style>

<style lang="scss" scoped>
::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: var(--neutral-000);
  border-radius: 0 0 8px 0;
}

::-webkit-scrollbar-thumb {
  background-color: var(--neutral-300);
  border-radius: 100px;
  border: 3px solid var(--neutral-000);
}

::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: var(--neutral-200);
}

.icon {
  width: 14px;
  height: 14px;
}
</style>
